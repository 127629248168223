import React from 'react'
import styled from 'styled-components'
import { useGetWeeklyParamsQuery } from '../../walletSlice'
const WarnSpan = styled.span`
  color: ${(props) => props.theme.errorColor};
`
export default function Desc() {
  return (
    <div
      style={{
        backgroundColor: '#EAEFFF',
        padding: '20px 24px',
        color: 'rgba(0,0,0,0.85)',
        marginTop: '20px',
      }}
    >
      <WeeklyText></WeeklyText>
    </div>
  )
}

export function WeeklyText() {
  const { data } = useGetWeeklyParamsQuery()
  return (
    <>
      <div style={{ fontWeight: 500 }}>周结规则说明：</div>
      1. 您选择周结后，当月不支持转回月结，如有需求，仅可在
      <WarnSpan>每月1号</WarnSpan>调整结算规则；；
      <br /> 2. 跑长A业务的设备、非95计费的设备、平台托管设备
      <WarnSpan>不</WarnSpan>支持周结；
      <br /> 3. 您选择了周结，则账号下<WarnSpan>符合周结条件</WarnSpan>
      的设备本周收益，会在次周{data?.getmoney_startday}前放入钱包，
      <WarnSpan>
        每周{`${data?.getmoney_startday}~${data?.getmoney_endday}`}可自助提现
      </WarnSpan>
      （其他不符合周结条件的，次月初放入月结钱包）；
      <br /> 4. 周结会在6%税费基础上，自动扣除对应手续费（根据实际情况调整）。
    </>
  )
}
