import { WithdrawParams } from '../WithdrawalModal'
import { useAppSelector } from '@/redux'
import { currenceFormat } from '../..'
import {
  getWithdrawalTimeRange,
  getWithdrawAmountLimit,
  checkWithdrawalTime,
} from '@/utils/checkWithdrawParams'
import { useGetBalanceQuery, initialBalance } from '../../walletSlice'
import { useMemo } from 'react'
export default function useWithdrawParams(): WithdrawParams {
  const { data: balance = initialBalance } = useGetBalanceQuery(0)
  const withdrawParams = useAppSelector((state) => state.withdrawParams)
  const { payInfo, timestamp } = useAppSelector((state) => state.user)
  const withdrawTime = useMemo(() => {
    return getWithdrawalTimeRange({
      withdrawParams,
      collectionMethod: payInfo.collectionMethod,
    })
  }, [payInfo.collectionMethod, withdrawParams])
  const withdrawAmountLimit = useMemo(() => {
    return getWithdrawAmountLimit({
      withdrawParams,
      collectionMethod: payInfo.collectionMethod,
    })
  }, [payInfo.collectionMethod, withdrawParams])

  return {
    leftAmount: currenceFormat(balance.leftAmount),
    amountLimit: withdrawAmountLimit,
    timeRangeString: withdrawTime.map((item) => `本月${item}日`),
    timeRangeRaw: withdrawTime,
    available:
      checkWithdrawalTime({
        timestamp,
        withdrawParams,
        collectionMethod: payInfo.collectionMethod,
      }) &&
      currenceFormat(balance.leftAmount) >= Math.round(withdrawAmountLimit[0]),
  }
}
