import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form, Input, message } from 'antd'

import NoticeModal from '@/components/NoticeModal'
import { $ERROR_COLOR, $Link_COLOR } from '@/constants/styles'
import doCountdown from '@/utils/countdown'
import { BeneForm } from '.'
import { verifyPreCode, verifyNewCode, sendCaptchar } from '@/api/user'
interface Props {
  visible: string
  setModal: React.Dispatch<
    React.SetStateAction<'' | 'confirm' | 'bind' | 'certification'>
  >
  phoneNumber: string
  username: string
}
const Title = styled.div`
  text-align: center;
  font-size: 0.9em;
  margin-bottom: 20px;
  span {
    color: ${$ERROR_COLOR};
  }
`

export default (props: Props) => {
  const [form] = Form.useForm()
  const [confirmStage, setsetConfirmStatus] = useState(true) //是否是验证阶段

  const verifyParams = {
    phoneNumber: props.phoneNumber,
    verifyCode: '',
  }

  const [confirmDeadline, setConfirm] = useState(0)
  const [bindDeadline, setBind] = useState(0)
  const [captcharStatus, setCaptstatus] = useState(true)
  const [ticket, setTicket] = useState('')
  useEffect(() => {
    if (!captcharStatus) {
      form.validateFields()
      setCaptstatus(true)
    }
  }, [captcharStatus, form])
  return (
    <NoticeModal
      title="换绑手机号"
      okText={confirmStage ? '验证身份' : '确定绑定'}
      onOk={() => {
        if (confirmStage) {
          form.validateFields().then((values: any) => {
            verifyPreCode(values)
              .then((res) => {
                form.setFieldsValue({
                  phoneNumber: '',
                  verifyCode: '',
                })
                setTicket(res?.ticket)
                setsetConfirmStatus(false)
                setTimeout(() => {
                  props.setModal('')
                }, 1000 * 60 * 60 * 12)
              })
              .catch((err) => {
                if (err?.response?.data === '验证码错误') {
                  setCaptstatus(false)
                }
              })
          })
        } else {
          form.validateFields().then((values: any) => {
            verifyNewCode({ ...values, ticket })
              .then((res) => {
                props.setModal('')
                message.success('修改成功')
              })
              .catch((err) => {
                if (err?.response?.data === '验证码错误') {
                  setCaptstatus(false)
                }
              })
          })
        }
      }}
      onCancel={() => {
        props.setModal('')
      }}
      visible={!!props.visible}
    >
      <Title>
        注意：登录后台仍然使用<span>{props.username}</span>
        ，新手机号是用于<span>验证收款信息</span>及<span>提现功能</span>
      </Title>
      <BeneForm
        initialValues={verifyParams}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 19 }}
        labelAlign="left"
        form={form}
      >
        {confirmStage && (
          <>
            <Form.Item label="原手机号：" required>
              <Form.Item noStyle name="phoneNumber">
                <Input readOnly style={{ width: '70%' }}></Input>
              </Form.Item>
              {!confirmDeadline && (
                <span
                  style={{
                    marginLeft: 'auto',
                    color: $Link_COLOR,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    sendCaptchar(form.getFieldValue('phoneNumber')).then(() => {
                      doCountdown({
                        deadline: 60,
                        setNumber: setConfirm,
                        callback: () => {},
                      })
                    })
                  }
                >
                  发送验证码
                </span>
              )}
              {!!confirmDeadline && (
                <span
                  style={{
                    marginLeft: 'auto',
                    color: '#666',
                    cursor: 'not-allowed',
                  }}
                >
                  {confirmDeadline}秒后重试
                </span>
              )}
            </Form.Item>
            <Form.Item
              label="验证码："
              name="verifyCode"
              rules={[
                { required: true, message: '请输入验证码' },
                () => ({
                  validator(rule, value) {
                    if (!value || (value && captcharStatus)) {
                      return Promise.resolve()
                    }
                    return Promise.reject('验证码错误')
                  },
                }),
              ]}
            >
              <Input placeholder="请输入6位验证码" />
            </Form.Item>
          </>
        )}
        {!confirmStage && (
          <>
            <Form.Item label="新手机号：" required>
              <Form.Item
                noStyle
                name="phoneNumber"
                rules={[{ required: true, message: '请输入新手机号' }]}
              >
                <Input
                  style={{ width: '70%' }}
                  placeholder="请输入新手机号"
                ></Input>
              </Form.Item>
              {!bindDeadline && (
                <span
                  style={{
                    marginLeft: 'auto',
                    color: $Link_COLOR,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    sendCaptchar(form.getFieldValue('phoneNumber')).then(() => {
                      doCountdown({
                        deadline: 60,
                        setNumber: setBind,
                        callback: () => {},
                      })
                    })
                  }
                >
                  发送验证码
                </span>
              )}
              {!!bindDeadline && (
                <span
                  style={{
                    marginLeft: 'auto',
                    color: '#666',
                    cursor: 'not-allowed',
                  }}
                >
                  {bindDeadline}秒后重试
                </span>
              )}
            </Form.Item>
            <Form.Item
              label="验证码："
              name="verifyCode"
              rules={[
                { required: true, message: '请输入验证码' },
                () => ({
                  validator(rule, value) {
                    if (!value || (value && captcharStatus)) {
                      return Promise.resolve()
                    }
                    return Promise.reject('验证码错误')
                  },
                }),
              ]}
            >
              <Input placeholder="请输入6位验证码" />
            </Form.Item>
          </>
        )}
      </BeneForm>
    </NoticeModal>
  )
}
