import React from 'react'
import { useHistory } from 'react-router-dom'

import NoticeModal from '@/components/NoticeModal'

interface Props {
  visible: string
}
export default (props: Props) => {
  const history = useHistory()
  return (
    <NoticeModal
      onCancel={() => history.goBack()}
      title=""
      okText="前往认证"
      onOk={() => {
        history.push('./certification')
      }}
      visible={!!props.visible}
    >
      <div style={{ color: '#333', textAlign: 'center', fontWeight: 'bold' }}>
        填写收款信息前，需要您先进行实名认证
      </div>
    </NoticeModal>
  )
}
