import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'
import './assets/tailwind.css'
import './styles/common.css'
import App from './route/App'
import * as serviceWorker from './serviceWorker'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family:"PingFang SC", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Arial, "Hiragino Sans GB", "Source Han Sans", "Noto Sans CJK Sc", "Microsoft YaHei", "Microsoft Jhenghei", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  html,body,p,ol,ul,li,dl,dt,dd,blockquote,figure,fieldset,legend,textarea,pre,iframe,hr,h1,h2,h3,h4,h5,h6 {
    margin:0;
    padding:0;
  }
  h1,h2,h3,h4,h5,h6 {
    font-size:100%;
    font-weight:normal;
  }
  ul {
    list-style:none;
  }
  button,input,select,textarea { margin:0 }
  html { box-sizing: border-box; }
  *, *::before, *::after {
    box-sizing:inherit
  }
  img, video {
    height:auto;
    max-width:100%;
  }
  iframe { border:0 }
  table {
    border-collapse:collapse;
    border-spacing:0
  }
  td,th { padding:0 }
  td:not([align]), th:not([align]) { text-align:left
  
  .ant-picker-cell-inner{
    text-align:center
  }
   }

  a {
    color: #2E5BFF;
  }

  #root .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: rgb(13, 15, 48);
  }

  .slider-dots-container {
    position: absolute;
    bottom: 32px;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slider-dots-container li button {
    width: 54px;
    height: 8px;
    background-color: #ffffff;
    opacity: 0.5;
    font-size: 0;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
  }

  .slider-dots-container li:not(:last-child) button {
    margin-right: 24px;
  }

  .slider-dots-container li.slick-active button {
    opacity: 1;
  }

  @media screen and (max-width: 480px) {
    .slider-dots-container {
      bottom: 13px;
    }
    .slider-dots-container li button {
      width: 27px;
      height: 4px;
      border-radius: 2px;
    }
    .slider-dots-container li:not(:last-child) button {
      margin-right: 12px;
    }
  }
`

ReactDOM.render(
  <>
    <App />
    <GlobalStyle />
  </>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
