import React from 'react'
import { useSelector } from 'react-redux'

import { WithdrawItem } from '@/redux/withdraw'
import NoticeModal from '@/components/NoticeModal'
import { getBillTimeRange } from '@/utils/checkWithdrawParams'

import { $ERROR_COLOR } from '@/constants/styles'
interface Props {
  visible: string
  onOk: () => void
  onCancel: () => void
}
export default (props: Props) => {
  const { payInfo } = useSelector((state: any) => state.user)
  const withdrawParams = useSelector(
    (state: { withdrawParams: WithdrawItem[] }) => state.withdrawParams,
  )
  return (
    <NoticeModal
      closable={false}
      title=""
      okText="确定"
      onOk={props.onOk}
      onCancel={props.onCancel}
      cancelText="取消"
      visible={!!props.visible}
    >
      <div style={{ textAlign: 'center' }}>
        仅
        <span style={{ color: $ERROR_COLOR }}>{`每月${
          getBillTimeRange({
            withdrawParams,
            collectionMethod: payInfo.collectionMethod,
          })[0]
        }日~${
          getBillTimeRange({
            withdrawParams,
            collectionMethod: payInfo.collectionMethod,
          })[1]
        }日`}</span>
        可修改收款信息，提现账户以提现时的收款信息为准，
        <span style={{ color: '#333', fontWeight: 'bold' }}>确定提交吗？</span>
      </div>
    </NoticeModal>
  )
}
