import React, { useMemo, useState } from 'react'
import { Drawer, Divider, Button, message, Popconfirm } from 'antd'
import { useBindManageLineMutation, DialItem } from './manageLineSlice'
import styled from 'styled-components'
import { $ERROR_COLOR, $Link_COLOR } from '@/constants/styles'
import Empty from './components/Empty'
import useQueryData from './hooks/useQueryData'
import Tips from './components/Tips'
import { useHistory } from 'react-router-dom'
const LineCardItem = styled.div`
  background: #eee;
  padding: 6px 16px;
  border-radius: 4px;
  margin-top: 15px;
  box-sizing: border-box;
  height: 34px;
  &.checked {
    color: #fff;
    background-color: ${$Link_COLOR};
  }
  &.binded {
    width: 80%;
  }
`

export default function ManageLineModal() {
  const { manageLineData, setManageLineData, data, hasBinded } = useQueryData()
  const history = useHistory()
  const [bind] = useBindManageLineMutation()
  const [selected, setSelect] = useState('')
  //在线路为空或为绑定前且未选择
  return (
    <Drawer
      onClose={() => {
        setManageLineData((c) => {
          return { ...c, deviceUUID: '' }
        })
      }}
      title="配置网络"
      width={'600px'}
      open={!!manageLineData.deviceUUID}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {data.dialList.length === 0 && <Empty></Empty>}
        {data.dialList.length > 0 && (
          <div style={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
            {hasBinded ? (
              <BindedList></BindedList>
            ) : (
              <UnBindedList
                selected={selected}
                setSelect={setSelect}
              ></UnBindedList>
            )}
            <Tips></Tips>
          </div>
        )}

        <Button
          disabled={
            data.dialList.length === 0 || (selected === '' && !hasBinded)
          }
          type="primary"
          onClick={() => {
            if (hasBinded) {
              history.push(
                '/dial/config?deviceUUID=' + manageLineData.deviceUUID,
              )
              setManageLineData((c) => {
                return { ...c, deviceUUID: '' }
              })
            } else {
              bind({
                card: selected,
                deviceUUID: manageLineData.deviceUUID,
              })
                .unwrap()
                .then(() => {
                  message.success('绑定成功')
                  manageLineData.onManageChange()
                })
            }
          }}
        >
          {hasBinded ? '去拨号' : '确认管理线路'}
        </Button>
      </div>
    </Drawer>
  )
}

function BindedList() {
  const { data, manageLineData } = useQueryData()
  const list = data.dialList
  const bindedLine = list.find((i) => i.isManager)
  const [bind] = useBindManageLineMutation()
  return (
    <>
      <div>您的管理线路网卡是</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LineCardItem className="checked binded">
          {bindedLine && <ItemContent {...bindedLine}></ItemContent>}
        </LineCardItem>
        <Popconfirm
          title="确定解绑管理网卡？"
          onConfirm={() => {
            bind({ deviceUUID: manageLineData.deviceUUID, unConfirm: true })
              .unwrap()
              .then(() => {
                message.success('解绑成功')
                manageLineData.onManageChange()
              })
          }}
        >
          <Button
            style={{ marginLeft: 'auto', marginTop: '15px' }}
            type="primary"
            ghost
          >
            解绑
          </Button>
        </Popconfirm>
      </div>
      <div style={{ color: '#666', marginTop: '16px' }}>您的数据线路是</div>
      {list
        .filter((i) => !i.isManager)
        .map((i) => {
          return (
            <LineCardItem key={i.name}>
              <ItemContent {...i}></ItemContent>
            </LineCardItem>
          )
        })}
    </>
  )
}

function UnBindedList({
  setSelect,
  selected,
}: {
  setSelect: React.Dispatch<React.SetStateAction<string>>
  selected: string
}) {
  const { data } = useQueryData()
  const list = data.dialList
  return (
    <>
      <div style={{ color: '#666' }}>该设备已启用{list.length}个网卡</div>
      <div>请选中一个为管理线路网卡：</div>
      {list.map((i) => {
        return (
          <LineCardItem
            key={i.name}
            className={selected === i.name ? 'checked' : ''}
            onClick={() => {
              setSelect(i.name)
            }}
            style={{ cursor: 'pointer' }}
          >
            <ItemContent {...i}></ItemContent>
          </LineCardItem>
        )
      })}
    </>
  )
}
function ItemContent(i: DialItem) {
  return (
    <>
      <span>网卡：{i.name}</span>
      <Divider type="vertical"></Divider>
      <span>速率：{i.speed}M</span>
      <Divider type="vertical"></Divider>
      <span>IP：{i.ip}</span>
    </>
  )
}
export { default as useCheckManagerLine } from './hooks/useCheckManagerLine'
