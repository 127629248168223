import { $ERROR_COLOR } from '@/constants/styles'
import { getMonthlyBillDetail } from '@/utils/api'
import { Avatar, Badge, Layout, Menu, Dropdown } from 'antd'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import packagejsonConfig from '../../package.json'
import LogoPng from '../assets/logo/logo-withtext-white.png'
import { logout } from '../redux/user/actions'
import { ManuItem, manuList } from '../views/pcenter'
import useMonthlyParams from '@/views/wallet/components/monthly/useWithdrawParams'
import useWeeklyParams from '@/views/wallet/components/weekly/useWithdrawParams'
import { createContext } from 'react'
import { logoutPrint } from '@/api/user'
import {
  ConfirmBillModal,
  NOTICE_STATUS,
  SlaModal,
  WeeklySettlement,
  noticeType,
} from './Modal'
import NoticeLogo from '@/assets/logo/消息.svg'
import NoticeList from './NoticeList'
import { getMessageList } from '@/api/message'
import { GlobalContainContext, useGlobalData } from './globalContext'
import ManageLineModal from '@/views/dial/manageLine'
const { Content, Sider, Header } = Layout

const InjectedStyle = createGlobalStyle`
  .ant-menu-dark, .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #0D0F30;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff;
    background:rgba(46,91,255,1);
    border-radius:0px 20px 20px 0px;
  }
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .ant-table {
    flex: 1;
  }
`

const LogoContainer = styled.div`
  position: relative;
  height: 64px;
  border-bottom: 0.5px solid rgba(216, 216, 216, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledRibbon = styled(Badge.Ribbon)`
  transform: scale(0.8);
  background: ${$ERROR_COLOR} !important;
  .ant-ribbon-corner {
    color: ${$ERROR_COLOR} !important;
  }
`

const Ribbon = (props: { text?: string; children: string }) => {
  if (props.text) {
    return <StyledRibbon text={props.text}>{props.children}</StyledRibbon>
  } else {
    return <span> {props.children}</span>
  }
}

const Logo = styled.div`
  width: 143px;
  height: 33px;
  object-fit: contain;
  background: url(${LogoPng}) center / contain no-repeat;
`

const VersionTooltip = styled.span`
  position: absolute;
  right: 4px;
  bottom: 0px;
  color: rgba(255, 255, 255, 0.15);
  font-size: 12px;
`

const LogoAndBrand: React.FC<{}> = () => (
  <LogoContainer>
    <Logo />
    <VersionTooltip>{packagejsonConfig.version}</VersionTooltip>
    {/* <BrandName>派享云</BrandName> */}
  </LogoContainer>
)
export const GlobalModalContext = createContext('')
const DeviceList: React.FC<{}> = ({ children }) => {
  const globalData = useGlobalData()
  const { pathname } = useLocation()
  useEffect(() => {
    getMonthlyBillDetail({
      date: moment().subtract(1, 'month').format('YYYY-MM'),
    })
  }, [])
  const { available: monthlyAvailable } = useMonthlyParams()
  const { available: weeklyAvailable } = useWeeklyParams()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const {
    role,
    username,
    needReconcileUser,
    collectionInfoFilled,
    needFillBillingCycle,
  } = useAppSelector((state) => state.user)

  const needShowBillModal = useMemo(() => {
    return (
      !collectionInfoFilled &&
      needReconcileUser &&
      !pathname.startsWith('/user')
    )
  }, [collectionInfoFilled, needReconcileUser, pathname])

  //以下包含三种弹窗，当其中一种弹窗关闭后入关没有其他操作,比如sla，需要检查另一种弹窗是否需要,
  //像bill和weekly-settlement弹窗这种，关闭后需要去填信息，直接关闭即可
  const setStatus = useCallback(() => {
    let noticeStorage = localStorage.getItem(NOTICE_STATUS) //存储的modal状态信息，可取值true，表示是否弹窗过sla
    if (noticeStorage !== 'true') {
      return 'sla' //如果从来没有打开过，则设置为sla，显示sla
    }
    //如果
    //bill弹窗的：1弹过sla，2.信息没补全。3.需要对账的用户,4.非用户中心页面
    if (needShowBillModal) {
      return 'bill'
    }
    //对私付款且结算方式字段为空，这个逻辑是后端判断的，这里只需要读取一个字段即可
    if (needFillBillingCycle) {
      return 'weekly-settlement'
    }
    return ''
  }, [needFillBillingCycle, needShowBillModal])

  const [noticeStatus, setNoticeStatus] = useState<noticeType>(setStatus)
  const recheckModalStatus = useCallback(
    () => setNoticeStatus(setStatus()),
    [setStatus],
  )

  useEffect(() => {
    recheckModalStatus()
  }, [recheckModalStatus])
  useEffect(() => {
    //因为bill弹窗在某些路径不会显示，因此在路径切换时检查要不要显示补全信息的弹窗
    if (noticeStatus === '' && needShowBillModal) {
      setNoticeStatus('bill')
    }
  }, [needShowBillModal, noticeStatus, pathname])

  const BASE_NUM = 20
  const [noticeType, setNoticeType] = useState<'unread' | 'all' | string>(
    'unread',
  )
  const [noticeList, setNoticeList] = useState<
    { id: string; title: string; level: string; sendTime: string }[]
  >([])
  const [total, setTotal] = useState(0)
  const [noReadCount, setNoReactCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [pageConfig, setPageConfig] = useState({
    pageIndex: 1,
    pageSize: BASE_NUM,
  })
  const fetchData = useCallback((config = {}) => {
    setLoading(true)
    getMessageList({
      ...config,
    })
      .then(({ total, list }) => {
        list = list.map((one) => {
          if (one.sendTime) {
            one.sendTime = moment(one.sendTime).format('YYYY-MM-DD HH:mm:ss')
          }
          return one
        })
        setTotal(total)
        if (config.pageIndex !== 1) {
          setNoticeList((nowlist) => {
            const newList = nowlist.concat(list)
            return newList
          })
        } else {
          setNoticeList(list)
        }

        if (config.type === 'unread') {
          setNoReactCount(total)
        }
      })
      .catch((err) => err)
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const loadMore = useCallback(() => {
    setPageConfig((pageConfig) => {
      const newPageConfig = Object.assign({}, pageConfig)
      newPageConfig.pageIndex = newPageConfig.pageIndex + 1
      return newPageConfig
    })
  }, [setPageConfig])

  useEffect(() => {
    fetchData({
      ...pageConfig,
      type: noticeType,
    })
  }, [fetchData, pageConfig, noticeType])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {noticeStatus === 'sla' && (
        <SlaModal
          recheckModalStatus={recheckModalStatus}
          needShowBillModal={needShowBillModal}
          visible={!!noticeStatus}
        ></SlaModal>
      )}
      {noticeStatus === 'bill' && (
        <ConfirmBillModal
          setNoticeStatus={setNoticeStatus}
          visible={!!noticeStatus}
        ></ConfirmBillModal>
      )}
      {noticeStatus === 'weekly-settlement' && (
        <WeeklySettlement
          setNoticeStatus={setNoticeStatus}
          visible={!!noticeStatus}
        ></WeeklySettlement>
      )}
      <InjectedStyle />
      <Sider
        style={{
          background: '#0D0F30',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <LogoAndBrand />
        <Menu
          theme="dark"
          mode="inline"
          style={{
            paddingRight: 15,
            height: 'calc(100% - 64px)',
            display: 'flex',
            flexDirection: 'column',
          }}
          selectedKeys={[pathname]}
          openKeys={[pathname.replace(/^\W(\w*)\W.*/, '$1')]}
        >
          <Menu.Item
            key="/dashboard/bill"
            onClick={() => {
              history.push('/dashboard/bill')
            }}
          >
            <span>数据总览</span>
          </Menu.Item>
          {/* <Menu.Item
            key="/device/list-temp"
            onClick={() => {
              history.push('/device/list-temp')
            }}
          >
            <span>原来的节点列表</span>
          </Menu.Item> */}
          <Menu.Item
            key="/device/list"
            onClick={() => {
              history.push('/device/list')
            }}
          >
            <span>节点列表</span>
          </Menu.Item>
          <Menu.Item
            key="/deploy"
            onClick={() => {
              history.push('/deploy')
            }}
          >
            <span>定向业务</span>
          </Menu.Item>
          {role === 'agent' && (
            <Menu.Item
              key="/miner/list"
              onClick={() => {
                history.push('/miner/list')
              }}
            >
              <span>用户列表</span>
            </Menu.Item>
          )}

          {needReconcileUser && (
            <Menu.Item
              key="/wallet"
              onClick={() => {
                history.push('/wallet')
              }}
            >
              <Ribbon
                text={monthlyAvailable || weeklyAvailable ? '可提现' : ''}
              >
                账单结算
              </Ribbon>
            </Menu.Item>
          )}

          {role !== 'agent' && (
            <Menu.Item
              key="/help/bind_device"
              onClick={() => {
                history.push('/help/bind_device')
              }}
            >
              <span>绑定节点</span>
            </Menu.Item>
          )}

          <Menu.SubMenu
            title="用户中心"
            key="user"
            onTitleClick={(params) => {
              history.push('/user/')
            }}
          >
            {manuList
              .filter((item: ManuItem) => {
                return (
                  needReconcileUser ||
                  !item.authorities?.includes('needReconcileUser')
                )
              })
              .map((item: ManuItem) => {
                return (
                  <Menu.Item
                    key={item.path}
                    onClick={() => {
                      history.push(item.path)
                    }}
                  >
                    <span>{item.title}</span>
                  </Menu.Item>
                )
              })}
          </Menu.SubMenu>
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
            }}
          >
            <div
              style={{
                marginRight: '20px',
                fontSize: '14px',
                cursor: 'pointer',
              }}
            >
              <Dropdown
                overlay={
                  <NoticeList
                    setNoticeType={setNoticeType}
                    type={noticeType}
                    total={total}
                    noReadCount={noReadCount}
                    loadMore={loadMore}
                    noticeList={noticeList}
                    loading={loading}
                    refresh={(type: 'unread' | 'all' | string) => {
                      if (type === 'unread') {
                        setPageConfig((config) => {
                          const newConfig = Object.assign({}, config)
                          newConfig.pageIndex = 1
                          return newConfig
                        })
                      }
                    }}
                  />
                }
              >
                <Badge count={noReadCount}>
                  <img
                    src={NoticeLogo}
                    alt=""
                    style={{
                      display: 'inline-block',
                      width: '30px',
                      height: '30px',
                    }}
                  />
                  <span style={{ paddingRight: '5px' }}>消息</span>
                </Badge>
              </Dropdown>
            </div>
            <Avatar size={42} style={{ marginRight: 10 }}>
              {username.substr(0, 3)}
            </Avatar>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <p
                style={{
                  color: 'black',
                  fontSize: 14,
                  lineHeight: 1,
                  marginBottom: 5,
                }}
              >
                {username}
              </p>
              <a
                href="/index"
                style={{ color: 'red', lineHeight: 1, fontSize: 14 }}
                onClick={(e) => {
                  e.preventDefault()
                  logoutPrint().finally(() => {
                    window.location.reload()
                  })
                  dispatch(logout())
                }}
              >
                退出
              </a>
            </div>
          </div>
        </Header>
        <GlobalContainContext.Provider value={globalData}>
          <GlobalModalContext.Provider value={noticeStatus}>
            <Content style={{ margin: '0 48px' }}>{children}</Content>
            {/* 防止不必要的渲染 */}
            {globalData.manageLineData.deviceUUID && (
              <ManageLineModal></ManageLineModal>
            )}
          </GlobalModalContext.Provider>
        </GlobalContainContext.Provider>
      </Layout>
    </Layout>
  )
}

export default DeviceList
